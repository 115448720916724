import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, getIn, useFormikContext } from 'formik';
import { chain, isEmpty, isNil, xor } from 'lodash';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CheckboxFormik from '~/components/CheckboxFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import ButtonsContainer from '~/components/core/Atomic/Buttons/ButtonsContainer';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { useCms } from '~/components/hooks/useCms';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import CardDialog from '../../../CardDialog';
import { Heading, LoadingSwitch } from '../../../core';
import { SubReservesFormik } from '../../../exposures/SubReservesFormik';
import { useSubReservesConfigurations } from '../../../hooks/useSubReserves';
import useOrganization from '../../../OrganizationContext';
import TextFieldFormik, { MonetaryValueTextFieldFormik, useSetDefaultFieldsOnChange } from '../../../TextFieldFormik';
import { CoveragesSingleSelectFormik } from '../../../TPA/Coverages/CoveragesSingleSelectFormik';
import LobRadioFormik from '../../../TPA/LOB/LobRadioFormik';
import SubOrganizationSelectFormik from '../../../TPA/SubOrganizations/SubOrganizationSelectFormik';
import Conditions, { CONDITION_FIELD_IDS } from '../Conditions/Conditions';

import InvolvedParties from './InvolvedParties';

const FIELD_IDS = {
  ID: 'id',
  DISPLAY_NAME: 'display_name',
  SUB_ORGANIZATION_ID: 'sub_organization_id',
  LOB: 'lob',
  COVERAGE_KEY: 'coverage_key',
  EXPENSES_RESERVE: 'expenses_reserve',
  EXPENSES_SUB_RESERVES: 'expenses_sub_reserves',
  INDEMNITY_RESERVE: 'indemnity_reserve',
  INDEMNITY_SUB_RESERVES: 'indemnity_sub_reserves',
  CONDITIONS: 'conditions',
  INVOLVED_PARTIES: 'involved_parties',
  USE_STAT_RESERVE: 'use_stat_reserve_configuration',
};

const AddOrEditRuleDialog = ({ onSubmit, onCancel, rule }) => {
  const { subOrganizationEnabled } = useOrganization();
  const {
    getIsExpensesSubReservesEnabled,
    getExpensesSubReservesConfigMap,
    getIsIndemnitySubReservesEnabled,
    getIndemnitySubReservesConfigMap,
  } = useSubReservesConfigurations();

  return (
    <Formik
      initialValues={{
        [FIELD_IDS.ID]: rule?.id || '',
        [FIELD_IDS.DISPLAY_NAME]: rule?.display_name ?? '',
        [FIELD_IDS.SUB_ORGANIZATION_ID]: rule?.sub_organization_id ?? '',
        [FIELD_IDS.LOB]: rule?.lobs[0] ?? '',
        [FIELD_IDS.COVERAGE_KEY]: rule?.coverage_key ?? '',
        [FIELD_IDS.INDEMNITY_RESERVE]: rule?.indemnity_reserve ?? '',
        [FIELD_IDS.EXPENSES_RESERVE]: rule?.expenses_reserve ?? '',
        [FIELD_IDS.INDEMNITY_SUB_RESERVES]: rule?.indemnity_sub_reserves ?? {},
        [FIELD_IDS.EXPENSES_SUB_RESERVES]: rule?.expenses_sub_reserves ?? {},
        [FIELD_IDS.CONDITIONS]: rule?.conditions ?? [],
        [FIELD_IDS.INVOLVED_PARTIES]: rule?.involved_parties ?? [],
      }}
      validationSchema={Yup.object().shape({
        [FIELD_IDS.DISPLAY_NAME]: Yup.string().required('Required'),
        [FIELD_IDS.SUB_ORGANIZATION_ID]: subOrganizationEnabled
          ? Yup.number().required('Required')
          : Yup.number().strip(),
        [FIELD_IDS.LOB]: Yup.string().required('Required'),
        [FIELD_IDS.COVERAGE_KEY]: Yup.string().required('Required'),
        [FIELD_IDS.USE_STAT_RESERVE]: Yup.boolean(),
        [FIELD_IDS.INDEMNITY_RESERVE]: Yup.number().when(FIELD_IDS.USE_STAT_RESERVE, {
          is: false,
          then: Yup.number().required('Required'),
          otherwise: Yup.number(),
        }),
        [FIELD_IDS.INDEMNITY_SUB_RESERVES]: Yup.object().when(FIELD_IDS.COVERAGE_KEY, {
          is: (coverage_key) => getIsIndemnitySubReservesEnabled(coverage_key),
          then: () =>
            Yup.object().test('indemnity-sub-reserves', 'Required', (value, context) => {
              const subReservesConfigMap = getIndemnitySubReservesConfigMap(
                getIn(context.parent, FIELD_IDS.COVERAGE_KEY)
              );
              let errors = [];
              [...subReservesConfigMap.keys()].map((subReserveKey) => {
                if (value && isNil(value[subReserveKey])) {
                  errors.push(context.createError({ path: `${context.path}.${subReserveKey}`, message: 'Required' }));
                }
              });

              return isEmpty(errors) ? true : context.createError({ message: errors });
            }),
          otherwise: Yup.object().strip(),
        }),
        [FIELD_IDS.EXPENSES_RESERVE]: Yup.number().when(FIELD_IDS.USE_STAT_RESERVE, {
          is: false,
          then: Yup.number().required('Required'),
          otherwise: Yup.number(),
        }),
        [FIELD_IDS.EXPENSES_SUB_RESERVES]: getIsExpensesSubReservesEnabled()
          ? Yup.object().shape(
              chain([...getExpensesSubReservesConfigMap().keys()])
                .keyBy((key) => key)
                .mapValues(() => Yup.number().required('Required'))
                .value()
            )
          : Yup.object().strip(),
        [FIELD_IDS.CONDITIONS]: Yup.array().of(
          Yup.object().shape({
            [CONDITION_FIELD_IDS.CONDITION_KEY]: Yup.string().required('Required'),
            [CONDITION_FIELD_IDS.OPERATOR_1]: Yup.string().required('Required'),
            // if there will be operators options, make it required
            [CONDITION_FIELD_IDS.OPERATOR_2]: Yup.string().nullable(),
            [CONDITION_FIELD_IDS.VALUE_1]: Yup.mixed().required('Required'),
            [CONDITION_FIELD_IDS.VALUE_2]: Yup.mixed(),
          })
        ),
        [FIELD_IDS.INVOLVED_PARTIES]: Yup.array().min(1, 'Required'),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          if (values[FIELD_IDS.USE_STAT_RESERVE]) {
            delete values[FIELD_IDS.INDEMNITY_RESERVE];
            delete values[FIELD_IDS.EXPENSES_RESERVE];
          }
          await onSubmit(values);
          onCancel();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {() => {
        return <AddRuleInner onCancel={onCancel} />;
      }}
    </Formik>
  );
};

AddOrEditRuleDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  rule: PropTypes.object,
};

const AddRuleInner = ({ onCancel }) => {
  const { subOrganizationEnabled } = useOrganization();
  const [isLoadingConditions, setIsLoadingConditions] = useState(false);
  const [isLoadingInvolved, setIsLoadingInvolved] = useState(false);
  const { isSubmitting, handleSubmit, values } = useFormikContext();
  const [subOrgIds, setSubOrgIds] = useState([]);
  const { userOrganization } = useCms();
  const classes = useStyles();
  const isDisabled = isSubmitting || isLoadingConditions || isLoadingInvolved;
  const isEditing = !!getIn(values, FIELD_IDS.ID);

  useEffect(() => {
    const subOrgId = getIn(values, FIELD_IDS.SUB_ORGANIZATION_ID);
    if ((subOrgId && isEmpty(subOrgIds)) || (subOrgId && subOrgId !== subOrgIds[0])) {
      setSubOrgIds([subOrgId]);
    } else if (!isEmpty(subOrgIds) && !subOrgId) {
      setSubOrgIds([]);
    }
  }, [subOrgIds, values]);

  const lobs = getIn(values, FIELD_IDS.LOB) ? [getIn(values, FIELD_IDS.LOB)] : [];

  return (
    <CardDialog isDialog onClose={onCancel} maxWidth="sm" fullWidth title={isEditing ? 'Edit Rule' : 'Add Rule'}>
      <Grid container spacing={3} className="max-h-[650px] overflow-auto">
        <Grid item xs={12}>
          <Heading variant={Heading.TYPES.H3} className="mb-10">
            Rule Details
          </Heading>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextFieldFormik
                id={FIELD_IDS.DISPLAY_NAME}
                label="Rule Name"
                className={classes.textField}
                fullWidth
                disabled={isDisabled}
              />
            </Grid>
            {subOrganizationEnabled && (
              <Grid item xs={12}>
                <SubOrganizationSelectFormik fieldId={FIELD_IDS.SUB_ORGANIZATION_ID} disabled={isDisabled} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Heading variant={Heading.TYPES.H3} className="mb-10">
            Rule Exposure
          </Heading>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LobRadioFormik
                lobFieldId={FIELD_IDS.LOB}
                subOrganizationIds={subOrgIds}
                disabled={isDisabled}
                label="Line of Business"
              />
            </Grid>
            <Grid item xs={12}>
              <CoveragesSingleSelectFormik
                lobs={lobs}
                disableGeneral
                disabled={isDisabled}
                subOrganizationIds={subOrgIds}
                coverageFieldId={FIELD_IDS.COVERAGE_KEY}
              />
            </Grid>
            <Grid item xs={12}>
              <InvolvedParties
                subOrganizationIds={subOrgIds}
                lob={getIn(values, FIELD_IDS.LOB)}
                coverage={getIn(values, FIELD_IDS.COVERAGE_KEY)}
                involvedPartiesFieldId={FIELD_IDS.INVOLVED_PARTIES}
                disabled={isDisabled}
                setIsLoading={setIsLoadingInvolved}
              />
            </Grid>
            {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.STAT_RESERVES) ? (
              <Grid item xs={12}>
                <CheckboxFormik
                  id={FIELD_IDS.USE_STAT_RESERVE}
                  label="Use stat reserve configuration"
                  disabled={isDisabled || isEmpty(getIn(values, FIELD_IDS.COVERAGE_KEY))}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <RuleReservesFormik isDisabled={isDisabled || getIn(values, FIELD_IDS.USE_STAT_RESERVE)} />
            </Grid>
            <Grid item xs={12}>
              <Conditions
                automation_type="automatic_exposures"
                subOrganizationIds={subOrgIds}
                lobs={[getIn(values, FIELD_IDS.LOB)]}
                coverage={getIn(values, FIELD_IDS.COVERAGE_KEY)}
                conditionsFieldId={FIELD_IDS.CONDITIONS}
                disabled={isDisabled}
                setIsLoading={setIsLoadingConditions}
                shouldLoadConditions={!!getIn(values, FIELD_IDS.COVERAGE_KEY)}
                loadConditionsFieldsIds={FIELD_IDS.COVERAGE_KEY}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ButtonsContainer>
        <CancelButton disabled={isDisabled} onClick={onCancel} />
        <Button variant="contained" color="primary" disabled={isDisabled} onClick={handleSubmit}>
          Save
        </Button>
      </ButtonsContainer>
    </CardDialog>
  );
};

AddRuleInner.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

const RuleReservesFormik = ({ isDisabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const {
    isLoading,
    isError,
    getIsExpensesSubReservesEnabled,
    getExpensesSubReservesConfigMap,
    getIsIndemnitySubReservesEnabled,
    getIndemnitySubReservesConfigMap,
  } = useSubReservesConfigurations();
  const classes = useStyles();
  const coverage_key = getIn(values, FIELD_IDS.COVERAGE_KEY);
  isDisabled = isDisabled || isEmpty(coverage_key);

  const getInitialSubReserves = useCallback(
    (getSubReservesConfigMapFunc) => {
      if (!coverage_key) {
        return {};
      }

      const configMap = getSubReservesConfigMapFunc(coverage_key);

      if (!configMap) {
        return {};
      }

      return chain([...configMap.keys()])
        .keyBy((key) => key)
        .mapValues(() => 0)
        .value();
    },
    [coverage_key]
  );

  const resetSubReserves = useCallback(
    (fieldId, getSubReservesConfigMapFunc) => {
      const currSubReserves = getIn(values, fieldId);
      const initialSubReserves = getInitialSubReserves(getSubReservesConfigMapFunc);
      if (
        !isNil(initialSubReserves) &&
        (isNil(currSubReserves) || xor(Object.keys(initialSubReserves), Object.keys(currSubReserves)).length > 0)
      ) {
        setFieldValue(fieldId, initialSubReserves);
        return initialSubReserves;
      }

      return currSubReserves;
    },
    [getInitialSubReserves, setFieldValue, values]
  );

  useEffect(() => {
    resetSubReserves(FIELD_IDS.EXPENSES_SUB_RESERVES, getExpensesSubReservesConfigMap);
  }, [getExpensesSubReservesConfigMap, getInitialSubReserves, resetSubReserves, setFieldValue]);

  useSetDefaultFieldsOnChange(
    coverage_key,
    {
      [FIELD_IDS.INDEMNITY_SUB_RESERVES]: resetSubReserves(
        FIELD_IDS.INDEMNITY_SUB_RESERVES,
        getIndemnitySubReservesConfigMap
      ),
    },
    null,
    null,
    true
  );

  const getExpensesReservesComponent = () => {
    if (getIn(values, FIELD_IDS.USE_STAT_RESERVE)) {
      return <TextFieldFormik id="expensesFromStatReserve" label="Amount from stat reserve" fullWidth disabled />;
    }
    if (getIsExpensesSubReservesEnabled()) {
      return (
        <SubReservesFormik
          subReservesFieldId={FIELD_IDS.EXPENSES_SUB_RESERVES}
          totalFieldId={FIELD_IDS.EXPENSES_RESERVE}
          totalFieldLabel="Total Expenses Reserve"
          subReservesConfigMap={getExpensesSubReservesConfigMap()}
          isDisabled={isDisabled}
        />
      );
    } else {
      return (
        <MonetaryValueTextFieldFormik
          id={FIELD_IDS.EXPENSES_RESERVE}
          label="Expenses Reserve"
          className={classes.textField}
          fullWidth
          disabled={isDisabled}
        />
      );
    }
  };

  const getIndemnityReservesComponent = () => {
    if (getIn(values, FIELD_IDS.USE_STAT_RESERVE)) {
      return <TextFieldFormik id="indemnityFromStatReserve" label="Amount from stat reserve" fullWidth disabled />;
    }
    if (coverage_key && getIsIndemnitySubReservesEnabled(coverage_key)) {
      return (
        <SubReservesFormik
          subReservesFieldId={FIELD_IDS.INDEMNITY_SUB_RESERVES}
          totalFieldId={FIELD_IDS.INDEMNITY_RESERVE}
          totalFieldLabel="Total Indemnity Reserve"
          subReservesConfigMap={getIndemnitySubReservesConfigMap(coverage_key)}
          isDisabled={isDisabled}
        />
      );
    } else {
      return (
        <MonetaryValueTextFieldFormik
          id={FIELD_IDS.INDEMNITY_RESERVE}
          label="Indemnity Reserve"
          className={classes.textField}
          fullWidth
          disabled={isDisabled}
        />
      );
    }
  };

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {getIndemnityReservesComponent()}
        </Grid>
        <Grid item xs={6}>
          {getExpensesReservesComponent()}
        </Grid>
      </Grid>
    </LoadingSwitch>
  );
};

RuleReservesFormik.propTypes = {
  isDisabled: PropTypes.bool,
};

export default AddOrEditRuleDialog;
export { FIELD_IDS };
