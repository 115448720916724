import React from 'react';
import axios from 'axios';
import type { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { Formik } from 'formik';

import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import ButtonsContainer from '~/components/core/Atomic/Buttons/ButtonsContainer';
import CancelButton from '~/components/core/Buttons/CancelButton';
import useOrganization from '~/components/OrganizationContext';
import type { StatReserveConfiguration } from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationPage';
import { MonetaryValueTextFieldFormik } from '~/components/TextFieldFormik';
import { reportAxiosError } from '~/Utils';

export interface StatReserveUpsertDialogProps {
  statReserveConfig: StatReserveConfiguration;
  onSubmit: () => void;
  onClose: () => void;
}

const indemnityReserveAmountNameKey: keyof StatReserveConfiguration = 'indemnity_reserve_amount';
const expensesReserveAmountNameKey: keyof StatReserveConfiguration = 'expenses_reserve_amount';
const StatReserveUpsertDialog: React.FC<StatReserveUpsertDialogProps> = ({ statReserveConfig, onSubmit, onClose }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationId } = useOrganization();
  const handleSubmit = async (
    statReserveConfig: StatReserveConfiguration,
    formikHelpers: FormikHelpers<StatReserveConfiguration>
  ) => {
    try {
      if (statReserveConfig.id) {
        await axios.patch(
          `/api/v1/organizations/${organizationId}/stat_reserve_configuration/${statReserveConfig.id}`,
          statReserveConfig
        );
      } else {
        await axios.post(`/api/v1/organizations/${organizationId}/stat_reserve_configuration`, statReserveConfig);
      }
      onSubmit();
    } catch (error) {
      formikHelpers.setSubmitting(false);
      await reportAxiosError(error);
    }
  };
  return (
    <Formik initialValues={statReserveConfig} onSubmit={handleSubmit}>
      {(formikProps: FormikProps<FormikValues>) => {
        const { handleSubmit, isSubmitting } = formikProps;

        return (
          <CardDialog isDialog title={statReserveConfig.coverage_display_name} fullWidth onClose={onClose}>
            <div className="flex flex-col space-y-20">
              <MonetaryValueTextFieldFormik
                id={indemnityReserveAmountNameKey}
                label="Indemnity Reserve Amount"
                allowNegative={false}
              />
              <MonetaryValueTextFieldFormik
                id={expensesReserveAmountNameKey}
                label="Expenses Reserve Amount"
                allowNegative={false}
              />
            </div>
            <ButtonsContainer className="mt-40">
              <CancelButton disabled={isSubmitting} onClick={onClose} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                Save
              </Button>
            </ButtonsContainer>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

export default StatReserveUpsertDialog;
